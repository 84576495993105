.input-codigo {
	font-family: monospace; border-radius: 6px; border: 1px solid lightgrey; box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px; margin: 4px; padding-left: 8px; width: 36px; height: 42px; font-size: 32px; box-sizing: border-box; color: black; background-color: white;
}

.contenedor_datepicker{
	display: 'flex';
    alignItems: 'center';
}

.contenedor_datepicker, 
.contenedor_datepicker > div.react-datepicker-wrapper, 
.contenedor_datepicker > div > div.react-datepicker__input-container
.contenedor_datepicker > div > div.react-datepicker__input-container input {
   width: 100%;
}

/* HIDE RADIO */
.radio_metodo { 
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.span_metodo {
  display: block;
  text-align: center;
}

/* IMAGE STYLES */
.radio_metodo + img {
  cursor: pointer;
  opacity: 0.2;
}

/* PROFILE PICTURE */
.picture-container{
  position: relative;
  cursor: pointer;
  text-align: center;
}
.picture{
  width: 200px;
  height: 200px;
  background-color: #999999;
  border: 4px solid #CCCCCC;
  color: #FFFFFF;
  border-radius: 50%;
  margin: 0px auto;
  overflow: hidden;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}
.picture:hover{
  border-color: #2C2052;
}

.picture input[type="file"] {
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0 !important;
  position: absolute;
  top: 0;
  width: 100%;
}

.picture-src{
  width: 100%;
  height: 100%;
}

.icon{
  margin-top: 15px;
  display: inline;
  padding: 3px 10px;
  border-radius: 50%;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 18px;
  position: absolute;
  bottom: -18px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.bg-purple{
  background-color: #2C2052;
}

.picture-min{
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

/* CHECKED STYLES */
.radio_metodo:checked + img {
  /*outline: 2px solid #f00;*/
  opacity: 1;
}

//Progreso de orden
#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: lightgrey;
    padding-inline-start: 0px !important;
    padding-left: 0px !important;
}

#progressbar .active {
    color: #000000
}

#progressbar .venta {
    list-style-type: none;
    font-size: 12px;
    width: 33%;
    float: left;
    position: relative;
    text-align: center;
}

#progressbar #iniciada-li:before {
    font-family: 'Font Awesome 5 Free';
    content: "\f187";
}

#progressbar #preautorizada-li:before {
    font-family: 'Font Awesome 5 Free';
    content: "\f187";
}

#progressbar #complementada-li:before {
    font-family: 'Font Awesome 5 Free';
    content: "\f187";
}

#progressbar #autorizada-li:before {
    font-family: 'Font Awesome 5 Free';
    content: "\f187";
}

#progressbar #finalizada-li:before {
    font-family: 'Font Awesome 5 Free';
    content: "\f187";
}

#progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 18px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 25px;
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: #127427
}